@font-face {
    font-family: 'Cream DEMO';
    src: url('/public/cream-DEMO.ttf') format('truetype');
  }

a {
    text-decoration: none;
    color: #333333;
}

/* Floating Card */

.card_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    gap: 10px;
    padding: 15px;
}

.card_content_box {
    max-width: 100%;
    width: 180px;
    height: 125px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 30px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    visibility: visible;
    z-index: 2;
}

.card_content_box img {
    display: block;
    max-width: 100%;
    height: 60px;
    display: inline-block;
    object-fit: contain;
    padding: 4px;
}

.card_content_box_text {
    display: inline-block;
    font: normal normal 24px/24px Roboto;
    letter-spacing: 0px;
    padding-bottom: 5%;
}

/* Contact Card */

.card_container {
    position: relative;
    display: flex;
    margin-top: 3%;
    height: 35rem;
}

.card_container img { 
    position: absolute; 
    object-fit: cover;
    max-width: 68%;
    right: -15%;
    height: auto;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.card_content_contact {
    width: 35rem;
    text-align: left;
    padding: 50px;
}

.card_content_contact div {
    display: inline;
}

.card_content_contact_title {
    width: 500px;
    margin: 0 0 8px 0;
    font: normal normal bold 45px/50px 'Cream DEMO';
    letter-spacing: 0px;
    color: #AE3ADB;
}

.card_content_contact_title2 {
    margin: 0 0 8px 0;
    font: normal normal bold 45px/50px cream DEMO;
    letter-spacing: 0px;
    color: #7F00B2;
}

.card_content_contact_text {
    margin: 0 0 8px 0;
    font: normal normal 20px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
}

.card_content_contact_text2 {
    margin: 0 0 8px 0;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
}

.card_content_contact_text3 {
    margin: 0 0 8px 0;
    font: normal normal 20px/20px Roboto;
    letter-spacing: 0px;
    color: #333333;
}

.card_content_contact_text_question {
    margin-top: 25px;
    font: normal normal 17px/22px Roboto;
    letter-spacing: 0px;
    color: #333333;
}

.card_content_contact button {
    background: #AE3ADB 0% 0% no-repeat padding-box;
    color: #fff;
    border: none;
    border-radius: 32px;
    padding: 12px 30px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 1%;
}

.card_content_contact button:hover {
    background: #7F00B2 0% 0% no-repeat padding-box;
}

/* Simple Card */

.divContainer {
    position: relative;
    max-height: 800px;
    margin: 0 auto;
}

.imgForma2 {
    position: absolute;
    left: 0;
    top: -380px;
    width: 190px;
    height: 250px;
}

.imgForma2Mbile {
    display: none;
}

.blog-post {
    background-color: #FFFFFF;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-width: 70rem;
    max-height: 600px;
    padding: 2rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 48px;
    margin-bottom: 5rem;
  }

  .imgForma3 {
    position: absolute;
    width: 270px;
    height: 135px;
    top: -25%;
    z-index: -1;
    }

    .imgForma3Mobile {
        display: none;
    }

  .blog-post__img {
    width: 100%;
    border-radius: 0.8rem;
  }
  
  .blog-post__img img {
    width: 85%;
    cursor: pointer;
  }

    .blog-post__info {
        width: 100%;
        margin-left: 2rem;
        text-align: left;
        padding: 0 2rem;
    }
  
  .blog-post__info p {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    font: normal normal 20px/22px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

.blog-post__info_list {
    list-style: none;
    font: normal normal 18px/20px Roboto;
    padding: 0;
    margin: 0;
    margin-top: 1rem;
}

.blog-post__info_list li {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #333333;
}

/* Floating Contact Card */

.card_floating_contact {
    display: flex;
    width: 45%;
    height: 130px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    top: -65px;
}

.card_floating_content_contact {
    height: 100px;
    margin: auto;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 10px;
    text-align: center;
    font: normal normal bold 23px/0px Roboto;
    color: #333333;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

/* ------- Resposive Devices ------- */


/* Monitor */
@media only screen and (max-width: 1536px) {

    /* Floating Contact Card */

    /* Contact Card */
    .card_container img { 
        max-width: 60%;
    }

    .card_container {
        margin-bottom: -10%;
    }

    .card_content_contact_title {
        font-size: 40px;
    }

    .card_content_contact_title2 {
        font-size: 40px;
    }

    .card_content_contact_text {
        font-size: 18px;
    }

    .card_content_contact_text2 {
        font-size: 18px;
    }

    .card_content_contact_text3 {
        font-size: 18px;
    }

    .card_content_contact_text_question {
        font-size: 17px;
    }

    .card_content_contact button {
        font-size: 18px;
    }

    /* Simple Card */

    .imgForma2 {
        width: 150px;
        height: 200px;
    }

    /* Card Floating Contact */

    .card_floating_contact {
        width: 45%;
    }

}

@media only screen and (max-width: 1280px) {

    /* Floating Contact Card */

    .card_floating_contact {
        width: 50%;
    }

    /* Simple Card */
    
    .blog-post {
            width: 100%;
    }
      
      .blog-post__img {
         margin: 0 auto;
    }

      /* Contact Card */

    .card_container img {
        width: 70%;
        height: 75%;
    }

    .card_content_contact_title {
        font-size: 35px;
    }

    .card_content_contact {
        width: 50%;
    }

    .card_content_contact_title2 {
        font-size: 40px;
    }

    /* Simple Card */

    .imgForma2 {
        width: 100px;
        height: 160px;
    }

    /* Card Floating Contact */

    .card_floating_contact {
        width: 55%;
    }

    
}

/* Laptop */
@media only screen and (max-width: 1024px) {
    
    /* Contact Card */

    .card_container {
        flex-direction: column;
        margin-bottom: 18%;
    }

    .card_container img {
        max-width: 100%;
        height: 25rem;
    }

    .card_content_contact {
        width: 100%;
        margin: 0 auto;
        padding: 0;
    }

    .card_content_contact_title {
        font-size: 40px;
        letter-spacing: -0.03em;
        line-height: 1;
    }

    .card_content_contact button {
        margin-top: 0px;
        padding: 10px 20px;
        font-size: 16px;
    }

    .card_container img {
        margin-top: 15px;
        position: sticky;
        border-radius: 0px;
        width: 100%;
        object-fit: cover;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
        padding: 0px;
    }

    /* Simple Card */

    .blog-post {
        width: 85%;
        margin-top: 10%;
        flex-direction: column;
    }

    .blog-post__img {
        width: 85%;
        height: 300px;
    }

    .blog-post__img img {
        display: inline;
        width: 100%;
        height: 100%;
        object-fit: contain;
        
    }

    .blog-post__info {
        margin-left: 0;
        text-align: center;
        padding: 0;
    }      

    .imgForma2 {
        width: 80px;
        height: 140px;
    }

    /* Card Floating Contact */

    .card_floating_contact {
        width: 70%;
        margin-top: 5%;
    }
}

/* Tablets */
@media only screen and (max-width: 768px) {

    /* Floating Contact Card */


    /* Contact Card */

    .card_container {
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 5%;
    }

    .card_container img {
        max-width: 120%;
    }

    .card_content_contact_text {
        font-size: 1rem;
    }

    .card_content_contact_text2 {
        font-size: 1rem;
    }

    .card_content_contact_text3 {
        font-size: 1rem;
    }

    .card_content_contact_text_question {
        margin-top: 5px;
        font-size: 0.9rem;
    }

        /* Simple Card */

        .blog-post {
            padding: 1.5rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            border-radius: 25px;
        }
    
        .blog-post__img {
            width: 100%;
            height: 150px;
    
        }
    
        .blog-post__img img {
            display: inline;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        
        .blog-post__info p {
            text-align: left;
            font-size: 0.8rem;
        }
    
        .blog-post__info_list li {
            font-size: 0.9rem;
        }

        .imgForma2 {
            width: 65px;
            height: 90px;
            margin-top: -450px;
        }

        .imgForma2Mbile {
            display: block;
            position: absolute;
            right: 0;
            top: -450px;
            width: 75px;
            height: 100px;
        }

        .imgForma3 {
            display: none;
        }

        .imgForma3Mobile {
            display: block;
            position: absolute;
            width: 62px;
            height: 100px;
            margin-left: -75%;
            top: -20%;
            z-index: -1;
        }

        /* Card Floating Contact */ 

        .card_floating_contact {
            width: 70%;
            margin-top: 5%;
        }

        .card_floating_content_contact {
            height: auto;
            font-size: 22.5px;
            letter-spacing: -0.05em;
            line-height: 1;
        }
    
        .emailText {
            font-size: 19px;
            letter-spacing: -0.05em;
            line-height: 1;
        }
}

@media (max-width: 640px) {

}

/* Cell Phone */
@media only screen and (max-width: 475px) {

    .card_content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 100%;
        padding: 25px;
    }

    .card_content_box {
        border-radius: 30px;
        width: 200px;
        height: 95px;
    }

    .card_content_box img{
        width: 60px;
        height: 45px;
        padding-top: 10%;
    }

    .card_content_box:last-child {
        grid-column: span 2;
        margin-left: auto;
        margin-right: auto;
    }

    /* Contact Card */

    .card_content_contact {
        margin: 0 auto;
        margin-top: 45px;
        margin-bottom: 0%;
    }

    .card_content_contact_title {
        font-size: 1.8rem;
        letter-spacing: -0.03em;
        line-height: 1;
    }

    .card_content_contact_title2 {
        font-size: 1.8rem;;
        letter-spacing: -0.06em;
        line-height: 1;
    }

    .card_container {
        margin-bottom: -10%;
    }

    .card_container img {
        position: sticky;
        border-radius: 0px;
        width: 400px;
        height: 170px;
        object-fit: cover;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
        padding: 0px;
    }

    /* Floating Contact Card */

    .card_floating_contact {
        width: 98%;
        margin-left: -5%;
    }

    .card_floating_content_contact {
        height: auto;
        font-size: 17.5px;
        letter-spacing: -0.05em;
        line-height: 1;
    }

    .emailText {
        font-size: 14px;
        letter-spacing: -0.05em;
        line-height: 1;
    }

    /* Simple Card */

    .imgForma2 {
        width: 15%;
        height: 22%;
        margin-top: -400px;
    }

    .imgForma2Mbile {
        width: 20%;
        height: 22%;
    }
}





