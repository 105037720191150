@font-face {
  font-family: 'Cream DEMO';
  src: url('/public/cream-DEMO.ttf') format('truetype');
}

.form-error-message {

  display: none;
  
  }

.BoxContact {
    display: flex;
    width: 100%;
}

.BoxForm {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
}

.BoxImg {
    flex-basis: 60%;
    height: 100%;
}

.imageContact {
  margin-top: 6%;
  width: 100%;
  height: 100%;
  float: right;
  object-fit: cover;
}

.imageContact2 {
  display: none;
}

.thanksTitle {
  margin: 0 0 8px 0;
  font: normal normal bold 50px/45px 'Cream DEMO';
  letter-spacing: 0px;
  color: #7F00B2;
}

.bodyText {
  max-width: 520px;
  font: normal normal 18px/25px Roboto;
}

.BoxContact form {
    display: flex;
    flex-direction: column;
    width: 450px;
    gap: 15px;
    margin-right: 50px;
    margin-top: -5px;
}


@media (max-width: 1280px) {

    .BoxForm {
      padding-left: 5%;
    }

    .imageContact {
      height: 95%;
    }

    .BoxForm {
      padding-top: 5%;
    }
}

@media (max-width: 1024px) {
  
  .imageContact {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }

  .BoxForm {
    width: 400px;
  }

  .BoxForm form {
    width: 400px;
  }

}

@media (max-width: 768px) {

  .BoxContact {
    flex-direction: column;
  }

  .BoxForm {
    padding-top: 8%;
  }

  .bodyText {
    padding-right: 5%;
  }

  .imageContact {
    display: none;
  }

  .imageContact2 {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }


  .thanksTitle {
    font-size: 2.5rem;
  }

}

@media (max-width: 480px) {

  .textContent h1 {
    font-size: 1.5rem;
  }

  .textContent p {
    font-size: 1rem;
  }
  
  .BoxContact form {
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }

}
  