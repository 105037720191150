.slideContainer {
    position: relative;
    text-align: center;
    height: 81vh;
}

.forma1 {
    position: absolute;
    bottom: -8%;
    right: 0;
    height: 70%;
    z-index: 1;
}

.description {
    width: 600px;
    position: absolute;
    left: 10%;
    top: 20%;
    z-index: 999;
}

.description h1 {
    font-size: 50px;
    font-weight: 700;
    color: #7F00B2;
    text-shadow: 2px 1px 3px black;
}
.description p {
    margin-top: -25px;
    font-size: 22px;
    font-weight: 300;
    color: #333333;
    text-shadow: 1px 1px 2px black;
}

.carousel-boullt span {
    cursor: pointer;
    width: 45px;
    height: 12px;
    background-color: rgb(223, 221, 219);
    margin-left: 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.carousel-boullt span:hover {
    background-color: #fff;
}

.carousel-boullt {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image_carousel {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 85%;
}

/* Resposive Devices */

/* Monitor */
@media only screen and (max-width: 1536px) {

    .forma1 {
        top: 23%;
        height: 55%;
        width: 18%;
    }
}

@media only screen and (max-width: 1280px) {
    .slideContainer {
        height: 65vh;
    }

    .forma1 {
        top: 20%;
        height: 55%;
        width: 22%;
    }
}

/* Laptop */
@media only screen and (max-width: 1024px) {

    .forma1 {
        top: 18%;
        height: 55%;
        width: 20%;
    }

    .slideContainer {
        height: 60vh;
    }
}

/* Tablets */
@media only screen and (max-width: 768px) {
    .slideContainer {
        height: 55vh;
    }

    .image_carousel {
        width: 100%;
        height: 60%;
    }

    .forma1 {
        top: 12%;
        height: 45%;
        width: 29%;
    }
}

/* Cell Phone */
@media only screen and (max-width: 425px) {
    .slideContainer {
        height: 50vh;
    }

    .forma1 {
        top: 11%;
        height: 45%;
        width: 29%;
    }
}
